import type { PaginatorProps } from './paginator.types';
import { Pagination } from '@tryhackme/thm-ui-components';
import { StyledTableFooter, StyledTableFooterText, StyledTablePagination } from './paginator.styles';
export const Paginator: React.FC<PaginatorProps> = ({
  offset,
  itemsPerPage,
  totalEntries,
  currentPage,
  handleOnPageChange,
  hideAvailablePageText,
  ...nativeDivAttributes
}) => {
  const legendLimit = Math.min(offset + itemsPerPage, totalEntries);
  const totalPages = Math.ceil(totalEntries / itemsPerPage);
  return <StyledTableFooter $center={hideAvailablePageText} {...nativeDivAttributes} data-sentry-element="StyledTableFooter" data-sentry-component="Paginator" data-sentry-source-file="paginator.tsx">
      <StyledTableFooterText role="note" data-sentry-element="StyledTableFooterText" data-sentry-source-file="paginator.tsx">
        {`Showing ${offset + 1} to ${legendLimit} of ${totalEntries} entries`}
      </StyledTableFooterText>
      <StyledTablePagination data-sentry-element="StyledTablePagination" data-sentry-source-file="paginator.tsx">
        <Pagination hideAvailablePageText={hideAvailablePageText} totalPages={totalPages} selectedPage={currentPage} onChange={handleOnPageChange} data-sentry-element="Pagination" data-sentry-source-file="paginator.tsx" />
      </StyledTablePagination>
    </StyledTableFooter>;
};