import { NetworkState } from 'src/common/enums/networks';

export const formatNetworkState = (networkState: string) => {
  let textState = '';
  let dataState = '';

  switch (networkState) {
    case NetworkState.RUNNING: {
      textState = 'Running';
      dataState = 'running';
      break;
    }
    case NetworkState.RESETTING: {
      textState = 'Resetting';
      dataState = 'resetting';
      break;
    }
    case NetworkState.STOPPED: {
      textState = 'Stopped';
      dataState = 'stopped';
      break;
    }
    default: {
      break;
    }
  }

  return { textState, dataState };
};
