import { NetworkDiagramRoomNode } from 'src/common/interfaces';
import { RoomTask } from 'src/common/interfaces/tasks';

import { nodeLabelReplace } from './label-replace';

const getNewLabelAndImage = (node: NetworkDiagramRoomNode, taskDetails: RoomTask[]) => {
  let newImage = node.image;
  let newLabel = nodeLabelReplace(node.label);

  for (const change of node.change) {
    if (change.active && change.taskNo && change.questionNo) {
      const matchedTask = taskDetails?.find((task: { taskNo: number }) => task.taskNo === change.taskNo);
      const matchedQuestion = matchedTask?.questions?.find(
        (question: { questionNo: number }) => question.questionNo === change.questionNo,
      );

      if (matchedQuestion?.progress?.correct) {
        newImage = change.image;
        newLabel = nodeLabelReplace(change.label);
      }
    }
  }

  return { newImage, newLabel };
};

export const getNewNodesStructure = (currentNodes: NetworkDiagramRoomNode[], taskDetails: RoomTask[]) =>
  currentNodes
    .filter((node) => node.showOnMap)
    .map((node) => {
      const { newImage, newLabel } = getNewLabelAndImage(node, taskDetails);

      return {
        id: node.id,
        shape: 'image',
        image: newImage,
        label: newLabel,
        level: node.level ?? undefined,
      };
    });
