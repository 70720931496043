import { Location } from 'react-router-dom';

import { isTaskBreakdownRoom } from 'src/features/room/helpers';

const shouldHideRoomHeaderAndFooter = (location: Location) => {
  const [, route, params] = location.pathname.split('/');
  if (route !== 'room') {
    return false;
  }

  return !!params && isTaskBreakdownRoom(params);
};

export const shouldHideFooter = (location: Location) => {
  const shouldHideManagementDashboardFooter = () => {
    // always show footer on the user details page, nested under /client
    if (/\/client\/\w{24}\/users\/\w{24}/i.test(location.pathname)) return false;

    const paths = ['/client/', '/workspace', '/ctf-builder'];
    const isPathMatched = paths.some((path) => location.pathname.includes(path));
    return isPathMatched;
  };

  return shouldHideManagementDashboardFooter() || shouldHideRoomHeaderAndFooter(location);
};

export const shouldHideHeader = (location: Location) => shouldHideRoomHeaderAndFooter(location);
