import { faBooks, faPenClip, faUsers, faVideo } from '@fortawesome/pro-regular-svg-icons';

import { ShowHelpOptions } from '../room.types';

export const getHelpOptions = (showHelpOptions: ShowHelpOptions) => {
  const HELP_OPTIONS = [
    {
      id: 'knowledgeBase',
      value: 'Knowledge Base',
      title: 'Knowledge Base',
      type: 'button',
      icon: faBooks,
      isTargetBlank: true,
      to: 'https://help.tryhackme.com/en/collections/3601905-getting-started-with-tryhackme',
    },
    {
      id: 'askCommunity',
      value: 'Ask Community',
      title: 'Ask Community',
      type: 'button',
      icon: faUsers,
      isTargetBlank: true,
      to: 'https://discord.com/invite/tryhackme',
    },
  ];

  if (showHelpOptions.isWriteupsOptionVisible) {
    HELP_OPTIONS.unshift({
      id: 'WriteUps',
      value: 'Write-ups',
      title: 'Write-ups',
      icon: faPenClip,
      to: '',
      type: 'button',
      isTargetBlank: false,
    });
  }

  if (showHelpOptions.isVideoSolutionOptionVisible) {
    HELP_OPTIONS.unshift({
      id: 'VideoSolution',
      value: 'Video Solution',
      title: 'Video Solution',
      icon: faVideo,
      to: '',
      type: 'button',
      isTargetBlank: false,
    });
  }
  return HELP_OPTIONS;
};
