import type { GhostContentAPIOptions } from 'src/common/interfaces';
import type { ApiReducerPathType } from '../interfaces/api/api';

export * from './two-factor-auth';

export const SwagStoreURL = 'https://store.tryhackme.com/';
export const BASE_URL_V2 = '/api/v2/';
export const MAIN_DOMAIN_URL = 'https://tryhackme.com';
export const BASE_GHOST_CONTENT_URL = 'https://blog.tryhackme.com/ghost/api/content/';
export const API_REDUCER_PATH: ApiReducerPathType = 'api';

export enum FeatureFlagName {
  ZERO_DEMO_FLAG = 'ZERO_DEMO_FLAG',
  INTERCOM_ENABLED = 'INTERCOM_ENABLED',
  RECAPME_PAGE = 'RECAPME_PAGE',
  RECAPME_DASHBOARD_AD = 'RECAPME_DASHBOARD_AD',
  MFA_ENABLED = 'MFA_ENABLED',
  BOOKMARK_ROOM = 'BOOKMARK_ROOM',
  REACT_LEGAL_PAGE = 'REACT_LEGAL_PAGE',
  REACT_BADGES_PAGE = 'REACT_BADGES_PAGE',
  REACT_PERSONAL_BADGE_PAGE = 'REACT_PERSONAL_BADGE_PAGE',
  REACT_500_PAGE = 'REACT_500_PAGE',
  REACT_404_PAGE = 'REACT_404_PAGE',
  REACT_FORGOT_CODE_PAGE = 'REACT_FORGOT_CODE_PAGE',
  REACT_ABOUT_PAGE = 'REACT_ABOUT_PAGE',
  REACT_BANNED_PAGE = 'REACT_BANNED_PAGE',
  REACT_CONTACT_US_PAGE = 'REACT_CONTACT_US_PAGE',
  REACT_VOUCHER_STATS_PAGE = 'REACT_VOUCHER_STATS_PAGE',
  ROOMS_REBUILD = 'ROOMS_REBUILD',
  ROOMS_REBUILD_WAVE_0 = 'ROOMS_REBUILD_WAVE_0',
  ROOMS_REBUILD_WAVE_1 = 'ROOMS_REBUILD_WAVE_1',
  ROOMS_REBUILD_WAVE_2 = 'ROOMS_REBUILD_WAVE_2',
  ROOMS_REBUILD_WAVE_3 = 'ROOMS_REBUILD_WAVE_3',
  ROOMS_REBUILD_WAVE_4 = 'ROOMS_REBUILD_WAVE_4',
  ROOMS_REBUILD_WAVE_5 = 'ROOMS_REBUILD_WAVE_5',
  ROOMS_REBUILD_JOIN_ROOM = 'ROOMS_REBUILD_JOIN_ROOM',
  ROOMS_REBUILD_AWS_ROOMS = 'ROOMS_REBUILD_AWS_ROOMS',
  REACT_MY_ROOMS_PAGE = 'REACT_MY_ROOMS_PAGE',
  REACT_CAREERS_PAGE = 'REACT_CAREERS_PAGE',
  REACT_RESOURCES_PAGE = 'REACT_RESOURCES_PAGE',
  REACT_CLIENT_GUIDES_PAGE = 'REACT_CLIENT_GUIDES_PAGE',
  REACT_CLIENT_REPORTS_PAGE = 'REACT_CLIENT_REPORTS_PAGE',
  REACT_GLOSSARY_PAGE = 'REACT_GLOSSARY_PAGE',
  REACT_MANAGE_ACCOUNT_PAGE = 'REACT_MANAGE_ACCOUNT_PAGE',
  REACT_CLIENT_USERS_PAGE = 'REACT_CLIENT_USERS_PAGE',
  REACT_CLIENT_LEARNING_PATHS_PAGE = 'REACT_CLIENT_LEARNING_PATHS_PAGE',
  REACT_ADMIN_NETWORKS_PAGE = 'REACT_ADMIN_NETWORKS_PAGE',
  REACT_ADMIN_USER_FEEDBACK = 'REACT_ADMIN_USER_FEEDBACK',
  REACT_ADMIN_MANAGE_USERS_PAGE = 'REACT_ADMIN_MANAGE_USERS_PAGE',
  REACT_ADMIN_SUBMISSIONS_PAGE = 'REACT_ADMIN_SUBMISSIONS_PAGE',
  REACT_CTF_BUILDER_PAGE = 'REACT_CTF_BUILDER_PAGE',
  REACT_CLIENT_ASSIGNMENTS_PAGE = 'REACT_CLIENT_ASSIGNMENTS_PAGE',
  REACT_CLIENT_SETTINGS_PAGE = 'REACT_CLIENT_SETTINGS_PAGE',
  REACT_AWS_CLOUD_TRAINING = 'REACT_AWS_CLOUD_TRAINING',
  REACT_ADMIN_CORPORATE_PAGE = 'REACT_ADMIN_CORPORATE_PAGE',
  REACT_PRICING_PAGE = 'REACT_PRICING_PAGE',
  REACT_USER_OCCUPATION_PAGE = 'REACT_USER_OCCUPATION_PAGE',
  REACT_MANAGE_ROOM_TAB = 'REACT_MANAGE_ROOM_TAB',
  REACT_MANAGE_SINGLE_ROOM = 'REACT_MANAGE_SINGLE_ROOM',
  REACT_PAYMENT_PENDING_PAGE = 'REACT_PAYMENT_PENDING_PAGE',
  REACT_CHRISTMAS_PAGE = 'REACT_CHRISTMAS_PAGE',
  REACT_ACCESS_PAGE = 'REACT_ACCESS_PAGE',
  REACT_HACKTIVITIES_NEW_SEARCH = 'REACT_HACKTIVITIES_NEW_SEARCH',
  REACT_HACKTIVITIES_NEW_LEARN = 'REACT_HACKTIVITIES_NEW_LEARN',
  REACT_HACKTIVITIES_ROOMS_SECTION = 'REACT_HACKTIVITIES_ROOMS_SECTION',
  REACT_SUBSCRIPTION_FEEDBACK_PAGE = 'REACT_SUBSCRIPTION_FEEDBACK_PAGE',
  REACT_ONBOARDING_PAGE = 'REACT_ONBOARDING_PAGE',
  REACT_AWS_PRICING_PAGE = 'REACT_AWS_PRICING_PAGE',
  REACT_HOME_PAGE = 'REACT_HOME_PAGE',
  REACT_USER_ASSIGNMENTS_PAGE = 'REACT_USER_ASSIGNMENTS_PAGE',
  REACT_PUBLIC_PROFILE_PAGE = 'REACT_PUBLIC_PROFILE_PAGE',
  REACT_WORKSPACE_PAGE = 'REACT_WORKSPACE_PAGE',
  REACT_MOBILE_SIGNUP_PAGE = 'REACT_MOBILE_SIGNUP_PAGE',
  CUSTOMER_IO_IDENTIFY = 'CUSTOMER_IO_IDENTIFY',
  REACT_CAREERS_HUB_PAGE = 'REACT_CAREERS_HUB_PAGE',
  REACT_CAREERS_QUIZ_PAGE = 'REACT_CAREERS_QUIZ_PAGE',
  CAREER_PAGES_TABS = 'CAREER_PAGES_TABS',
  REACT_CYBER_SECURITY_ANALYST_CAREER_PAGE = 'REACT_CYBER_SECURITY_ANALYST_CAREER_PAGE',
  REACT_SECURITY_ENGINEER_CAREER_PAGE = 'REACT_SECURITY_ENGINEER_CAREER_PAGE',
  CERTIFICATION_EXAM = 'CERTIFICATION_EXAM',
  REACT_JUNIOR_SECURITY_ANALYST_CERTIFICATION_PAGE = 'REACT_JUNIOR_SECURITY_ANALYST_CERTIFICATION_PAGE',
  REACT_PENETRATION_TESTER_CAREER_PAGE = 'REACT_PENETRATION_TESTER_CAREER_PAGE',
  REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE = 'REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE',
  REACT_RED_TEAMER_CAREER_PAGE = 'REACT_RED_TEAMER_CAREER_PAGE',
  REACT_INCIDENT_RESPONDER_CAREER_PAGE = 'REACT_INCIDENT_RESPONDER_CAREER_PAGE',
  REACT_CHARGEBEE_RETENTION = 'REACT_CHARGEBEE_RETENTION',
  REACT_SIGNUP_MODAL = 'REACT_SIGNUP_MODAL',
  REACT_HEADER_SEARCH = 'REACT_HEADER_SEARCH',
  REACT_SIGNUP_PAGE = 'REACT_SIGNUP_PAGE',
  REACT_CUSTOMER_CARD_DETAILS = 'REACT_CUSTOMER_CARD_DETAILS',
  REACT_PAUSE_SUBSCRIPTION = 'REACT_PAUSE_SUBSCRIPTION',
  TEAM_SKILLS_MATRIX = 'TEAM_SKILLS_MATRIX',
  REACT_DASHBOARD_PAGE = 'REACT_DASHBOARD_PAGE',
  SINGLE_SIGN_ON = 'SINGLE_SIGN_ON',
  SOC_SIM = 'SOC_SIM',
  SOC_SIM_SIEM_NAVIGATION_ENABLED = 'SOC_SIM_SIEM_NAVIGATION_ENABLED',
  CUSTOMER_CARD_EXPIRY = 'CUSTOMER_CARD_EXPIRY',
  TUTOR_AI = 'TUTOR_AI',
  REACT_PATH_OUTLINE_PAGE = 'REACT_PATH_OUTLINE_PAGE',
  UPGRADE_TO_ANNUAL_SUBSCRIPTION = 'UPGRADE_TO_ANNUAL_SUBSCRIPTION',
  AZURE_CLOUD_TRAINING = 'AZURE_CLOUD_TRAINING',
  REACT_PATHS_PAGE = 'REACT_PATHS_PAGE',
  SHARING_CERTIFICATE = 'SHARING_CERTIFICATE',
  CTF_FEATURES = 'CTF_FEATURES',
  CTF_BUILDER_FUNCTIONS = 'CTF_BUILDER_FUNCTIONS',
  REACT_CONGRATULATIONS_PAGE = 'REACT_CONGRATULATIONS_PAGE',
  LOCALIZED_PRICING = 'LOCALIZED_PRICING',
  REACT_SUBSCRIPTIONS_PAGE = 'REACT_SUBSCRIPTIONS_PAGE',
  REACT_CHRISTMAS_PAGE_2024 = 'REACT_CHRISTMAS_PAGE_2024',
  REACT_DARK_MODE = 'REACT_DARK_MODE',
  MGTDASH_SOC_SIM = 'MGTDASH_SOC_SIM',
  REACT_UPLOAD_PAGE = 'REACT_UPLOAD_PAGE',
  COMBINE_START_MACHINE_ATTACKBOX_WIDGET = 'COMBINE_START_MACHINE_ATTACKBOX_WIDGET',
  REACT_YOUR_MATERIAL_PAGE = 'REACT_YOUR_MATERIAL_PAGE',
  REACT_WHY_SUBSCRIBE_PAGE = 'REACT_WHY_SUBSCRIBE_PAGE',
  UPDATED_B2B_PRICING = 'UPDATED_B2B_PRICING',
  REACT_LOGIN = 'REACT_LOGIN',
  WEBAPP_PENTESTING_PATH = 'WEBAPP_PENTESTING_PATH',
  WORKSPACE_AOC_2024 = 'WORKSPACE_AOC_2024',
  MY_LEARNING_PAGE = 'MY_LEARNING_PAGE',
  CHRISTMAS_LOGO = 'CHRISTMAS_LOGO',
  REACT_CLASSROOMS_PAGE = 'REACT_CLASSROOMS_PAGE',
  REACT_FEEDBACK_PAGE = 'REACT_FEEDBACK_PAGE',
  REACT_BUSINESS_CHECKOUT_PAGE = 'REACT_BUSINESS_CHECKOUT_PAGE',
  TUTOR_NOTIFICATIONS_TOGGLE = 'TUTOR_NOTIFICATIONS_TOGGLE',
  REACT_DEVELOP_ROOMS_PAGE = 'REACT_DEVELOP_ROOMS_PAGE',
  CERTIFICATES_PUBLIC_PROFILE = 'CERTIFICATES_PUBLIC_PROFILE',
  IS_SOC_SIM_PUBLIC = 'IS_SOC_SIM_PUBLIC',
  REACT_MODULE_PAGE = 'REACT_MODULE_PAGE',
  REACT_KOTH_PAGE = 'REACT_KOTH_PAGE',
  SOC_SIM_ON_LEARN_MAIN_NAV = 'SOC_SIM_ON_LEARN_MAIN_NAV',
  CHALLENGES_PROMOTION_BANNER = 'CHALLENGES_PROMOTION_BANNER',
  SOC_SIM_AD_BANNER_IN_PATHS = 'SOC_SIM_AD_BANNER_IN_PATHS',
  SHARE_BADGE_CONGRATULATIONS_PAGE = 'SHARE_BADGE_CONGRATULATIONS_PAGE',
  SOC_SIM_BANNER = 'SOC_SIM_BANNER',
  RETENTION_V2 = 'RETENTION_V2',
}

export enum RTKQueryTag {
  AllCompanies = 'AllCompanies',
  NetworkTemplates = 'NetworkTemplates',
  AssignmentDetails = 'AssignmentDetails',
  AWSCloudTrainingAccounts = 'AWSCloudTrainingAccounts',
  AWSCloudTrainingUsers = 'AWSCloudTrainingUsers',
  Badges = 'Badges',
  PersonalBadges = 'PersonalBadges',
  ReCapMe = 'ReCapMe',
  YourMaterialUploads = 'YourMaterialUploads',
  YourMaterialCustomInstances = 'YourMaterialCustomInstances',
  ContentEngineerMaterials = 'ContentEngineerMaterials',
  Company = 'Company',
  CompanyAssignments = 'CompanyAssignments',
  CompanyInstructors = 'CompanyInstructors',
  CompanyModules = 'CompanyModules',
  CompanyPaths = 'CompanyPaths',
  CompanySubscriber = 'CompanySubscriber',
  CompanySSODomains = 'CompanySSODomains',
  CompanyUserDetails = 'CompanyUserDetails',
  PathDetails = 'PathDetails',
  Experiments = 'Experiments',
  FeatureFlag = 'FeatureFlag',
  Glossary = 'Glossary',
  Hacktivities = 'Hacktivities',
  SkillsMatrix = 'SkillsMatrix',
  HacktivitiesAllRooms = 'HacktivitiesAllRooms',
  HacktivitiesAllSeries = 'HacktivitiesAllSeries',
  HacktivitiesPercentage = 'HacktivitiesPercentage',
  HacktivitiesSeriesDetail = 'HacktivitiesSeriesDetail',
  LearnHacktivities = 'LearnHacktivities',
  UserChallengeRoomsOverallProgress = 'UserChallengeRoomsOverallProgress',
  RecommendationRooms = 'RecommendationRooms',
  ManageRoomAccessMembers = 'ManageRoomAccessMembers',
  ManageUsers = 'ManageUsers',
  Networks = 'Networks',
  Notifications = 'Notifications',
  PublicPaths = 'PublicPaths',
  Room = 'Room',
  RecommendationRoomDetails = 'RecommendationRoomDetails',
  RunningInstances = 'RunningInstances',
  Scoreboard = 'Scoreboard',
  SingleCompany = 'SingleCompany',
  Submissions = 'Submissions',
  CurrentB2CSubscriptionBillingData = 'CurrentB2CSubscriptionBillingData',
  B2CSubscriptionUpgradeCostEstimation = 'B2CSubscriptionUpgradeCostEstimation',
  Task = 'Task',
  Tickets = 'Tickets',
  User = 'User',
  UserAssignments = 'UserAssignments',
  UserFeedback = 'UserFeedback',
  UserOccupation = 'UserOccupation',
  ManageRooms = 'ManageRooms',
  ManageCategories = 'ManageCategories',
  ManageRoomCategories = 'ManageRoomCategories',
  ManageRoomTags = 'ManageRoomTags',
  ManageRoomDetail = 'ManageRoomDetail',
  ManageRoomUsers = 'ManageRoomUsers',
  UserRooms = 'UserRooms',
  ManageUserWriteUps = 'ManageUserWriteUps',
  ManageRoomTasks = 'ManageRoomTasks',
  ManageRoomTaskMaterial = 'ManageRoomTaskMaterial',
  ManageRoomStatsScoreboard = 'ManageRoomStatsScoreboard',
  Tags = 'Tags',
  TagCategories = 'TagCategories',
  VpnServers = 'VpnServers',
  Workspace = 'Workspace',
  WorkspaceDiscovery = 'WorkspaceDiscovery',
  WorkspaceLeaderboard = 'WorkspaceLeaderboard',
  WorkspaceChallenges = 'WorkspaceChallenges',
  WorkspaceActivity = 'WorkspaceActivity',
  WorkspaceSocSimLeaderboard = 'WorkspaceSocSimLeaderboard',
  CloudAwsEnvDetails = 'CloudAwsEnvDetails',
  CloudAwsEnvCredentials = 'CloudAwsEnvCredentials',
  CloudAzureEnvCredentials = 'CloudAzureEnvCredentials',
  CloudAzureLabs = 'CloudAzureLabs',
  AzureTenantOnboardingCompanies = 'AzureTenantOnboardingCompanies',
  PathOutline = 'PathOutline',
  CompanyDisplayPaths = 'CompanyDisplayPaths',
  DashboardQuestionsChart = 'DashboardQuestionsChart',
  Roadmaps = 'Roadmaps',
  TutorMessages = 'TutorMessages',
  DashboardNotifications = 'DashboardNotifications',
  AzureLab = 'AzureLab',
  SOCSimLastIncidentId = 'SOCSimLastIncidentId',
  SOCSimAlerts = 'SOCSimAlerts',
  SOCSimAlertsInProgress = 'SOCSimAlertsInProgress',
  SOCSimAlertDetails = 'SOCSimAlertDetails',
  AzureLabs = 'AzureLabs',
  SocSimRun = 'SocSimRun',
  SocSimRunStats = 'SocSimRunStats',
  SocSimRunSummary = 'SocSimRunSummary',
  SocSimRunSummaryAlerts = 'SocSimRunSummaryAlerts',
  SocSimUserRuns = 'SocSimUserRuns',
  SocSimStats = 'SocSimStats',
  SocSimCaseReports = 'SocSimCaseReports',
  SocSimCaseReportDetails = 'SocSimCaseReportDetails',
  SocSimScenarios = 'SocSimScenarios',
  SocSimScenarioContent = 'SocSimScenarioContent',
  SocSimOnboardingSteps = 'SocSimOnboardingSteps',
  PathsCurrent = 'PathsCurrent',
  PathCurrent = 'PathCurrent',
  UserFriends = 'UserFriends',
  Teams = 'Teams',
  TeamMembers = 'TeamMembers',
  TeamInvitations = 'TeamInvitations',
  CertificationState = 'CertificationState',
  CertificationSectionState = 'CertificationSectionState',
  CertificationPurchase = 'CertificationPurchase',
  CertificationPreviousResults = 'CertificationPreviousResults',
  CertificationMultipleChoiceQuestions = 'CertificationMultipleChoiceQuestions',
  SocSimLeaderboard = 'SocSimLeaderboard',
  GetCertificate = 'GetCertificate',
  PathProgress = 'PathProgress',
  SocSimAlertRate = 'SocSimAlertRate',
  TeamAlertRate = 'TeamAlertRate',
  SocSimTeamStats = 'SocSimTeamStats',
  SocSimUserReports = 'SocSimUserReports',
  MaterialUploadStatus = 'MaterialUploadStatus',
  SocSimGuides = 'SocSimGuides',
  KothMachinePool = 'KothMachinePool',
  KothRecentGames = 'KothRecentGames',
  KothGame = 'KothGame',
  Challenges = 'Challenges',
  CategorizationRules = 'CategorizationRules',
  CategorizationSources = 'CategorizationSources',
  CategorizationRuleTitles = 'CategorizationRuleTitles',
  CategorizationContexts = 'CategorizationContexts',
  CategorizationCategories = 'CategorizationCategories',
  RoomNpsSubmitted = 'RoomNpsSubmitted',
  Module = 'Module',
  SOCSimTeamsPoints = 'SOCSimTeamsPoints',
  SOCSimTeamsRank = 'SOCSimTeamsRank',
  WorkspaceKPI = 'WorkspaceKPI',
  Campaigns = 'Campaigns',
  SocSimAlertTypes = 'SocSimAlertTypes',
  PathsCurrentModule = 'PathsCurrentModule',
  SocSimRunSummarySharedLinks = 'SocSimRunSummarySharedLinks',
  SocSimPublicRunSummary = 'SocSimPublicRunSummary',
  WorkspaceInviteTeammates = 'WorkspaceInviteTeammates',
  ModuleScoreboard = 'ModuleScoreboard',
  CTFLatestEvent = 'CTFLatestEvent',
  Leagues = 'Leagues',
  MitreTechniqueProgress = 'MitreTechniqueProgress',
  MitreAssignment = 'MitreAssignment',
  RoomBlueprint = 'RoomBlueprint',
  RoomBlueprintRules = 'RoomBlueprintRules',
  RoomBlueprintTasks = 'RoomBlueprintTasks',
  AssignmentScoreboard = 'AssignmentScoreboard',
}

export const GOOGLE_SITE_KEY = '6LdnORYTAAAAAD3XKUn8v29ONJnxgF87-OnLqYdv';
export const LOCAL_STORAGE_MANAGEMENT_DASHBOARD_NAVIGATION_STATE_ID = 'management-dashboard-navigation-state';

export const GhostContentOpts: GhostContentAPIOptions = {
  url: 'https://blog.tryhackme.com',
  key: 'a0ed05fe5ffc034e42ec78f0fb',
  version: 'v5.0',
};

export const HALF_MINUTE_IN_MS = 30_000;
export const ONE_MIN_IN_MS = 60_000;
export const ONE_DAY_IN_MS = 86_400_000; // 1000 * 60 * 60 * 24

export const INTERCOM_APP_ID = 'pgpbhph6';
export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';

export const COPY_TEXT = 'Copy Link';
export const COPIED_TEXT = 'Copied!';
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again.';

export const ZOOM_INFO_FORM_ID = '3880aa8e-0660-4082-bed8-3d23d65ec61f';
export const CHARGEBEE_SITE_ID = process.env.NODE_ENV === 'production' ? 'tryhackme' : 'tryhackme-test';
export const CHILI_PIPER_SUBDOMAIN = 'tryhackme';

export enum ChiliPiperRouterNames {
  B2B = 'b2b',
  AWS = 'aws',
  EDUCATION = 'edu',
}

export const SHOW_SPLIT_SCREEN = 'showSplitScreen';
export const TUTOR_SEEN_ROOMS = 'tutor_seen_rooms';
export const TUTOR_INTERACTED_WITH_TIMESTAMP = 'tutor_interacted_with_timestamp';
export const LOGIN_REFERRER = 'login_referrer';

export enum UserAnnualPricing {
  ANNUAL_PLAN_PRICE_USD = 126,
  ANNUAL_PLAN_PRICE_GBP = 108,
}

export enum ExperimentNames {
  ADD_PREMIUM_FREE_ROADMAP = 'addPremiumFreeRoadmap',
  ADD_PREMIUM_FREE_ROADMAP_V2 = 'addPremiumFreeRoadmapV2',
  TUTOR_SOFT_LAUNCH_V1 = 'tutorSoftLaunchV1',
  TUTOR_SOFT_LAUNCH_V2 = 'tutorSoftLaunchV2',
  COMBINE_START_MACHINE_ATTACKBOX_WIDGET = 'combineStartMachineAttackboxWidget',
}

export const AI_TUTOR_NAME = 'Echo';
export const DEFAULT_TUTOR_LANDING_PAGE_URL = `/r/${AI_TUTOR_NAME.toLowerCase()}`;
export const AI_TERMS_OF_USE_URL = '/r/legal/ai-terms-of-use';
export const TUTOR_STUCK_MESSAGE = "Hey, I'm stuck!";

export enum TutorEngagementPrompt {
  FIRST_TIME = "Welcome! I'm here to help you with real-time guidance, personalized hints, and explanations. 🚀",
  SUBSEQUENT = 'Welcome back! Need any assistance with your current task? I can help in this room as well. 🚀',
  DEFAULT = 'Stuck on a question? I am here to help you with real-time guidance, personalized hints, and explanations. 🚀',
}

export const EXPERIMENTS_CONFIG_COOKIE = 'thm-experiments-config';
